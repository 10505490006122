import { createReducer } from '@reduxjs/toolkit'
import { getAllHospitalTransfer, getAllHospitalTransferError, getAllHospitalTransferSuccess } from '../actions/hospitalTransferActions'

const initialState = {
    loading: false,
    transfers: [],
    singleTransfer: [],
    error: null,
}

const hospitalTransferReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getAllHospitalTransfer, (state) => {
            state.loading = true
        })
        .addCase(getAllHospitalTransferError, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
        .addCase(getAllHospitalTransferSuccess, (state, action) => {
            state.loading = false
            state.transfers = action.payload
        })
})

export default hospitalTransferReducer
