/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from "redux-saga/effects";
import url from "../../api";
import {
  getAllHospital,
  getAllHospitalError,
  getAllHospitalSuccess,
  getHospital,
  getHospitalError,
  getHospitalSuccess,
  updateHospital,
  updateHospitalError,
  updateHospitalSuccess,
  getRecHospitals,
  getRecHospitalsError,
  getRecHospitalsSuccess,
} from "../actions/hospitalActions";
import { toast } from "react-toastify";

import { storeAuthToken } from "../actions/authActions";
import { getAuthToken } from "./authSaga";

function* getHospitalsSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(url.get, `/dispatchers/${_authToken.user_id}`);
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/hospitals?limit=0`)
        : yield call(url.get, `/hospitals?limit=0&precinct_id=${precinct_id}`);
    
    let { data } = response.data;

    data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = "Low";
      }
      item.key = index;
      return item;
    });
    yield put(getAllHospitalSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getAllHospitalError(error));
    }
    const {
      data: { message },
    } = error.response;
    yield put(getAllHospitalError(message));
  }
}
function* getHospitalSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id } = action.payload;
    const response: any = yield call(url.get, `/hospitals/${id}`);
    const { data } = response.data;
    yield put(getHospitalSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getHospitalError(error));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getHospitalError(message));
  }
}

function* updateHospitalSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id, hospitalData, setVisible } = action.payload;
    const response: any = yield call(url.put, `/hospitals/${id}`, hospitalData);
    const { data } = response.data;
    toast.success("Case updated sucessfully");
    yield put(updateHospitalSuccess(data));
    yield put(getAllHospital());
    setVisible(false);
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateHospitalError(error));
    }

    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updateHospitalError(message));
  }
}

function* getRecHospitalSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { payload } = action;
    const response: any = yield call(url.post, "/recommendation/hospitals", payload);
    const { data } = response.data;
    yield put(getRecHospitalsSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getRecHospitalsError());
    }
    yield put(getRecHospitalsError());
  }
}
function* hospitalSaga() {
  yield takeLeading(getAllHospital, getHospitalsSaga);
  yield takeLeading(getHospital, getHospitalSaga);
  yield takeLeading(updateHospital, updateHospitalSaga);
  yield takeLeading(getRecHospitals, getRecHospitalSaga);
}

export default hospitalSaga;
