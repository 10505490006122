import { createAction } from '@reduxjs/toolkit'
import { USERS } from './types'


export const getAllUsers = createAction(
  USERS.getAllUsers
);
export const getAllUsersLookup = createAction(
  USERS.getAllUsersLookup
);

export const getAllUsersError = createAction<any>(
  USERS.getAllUsersError
)

export const getAllUsersSuccess = createAction<any>(
  USERS.getAllUsersSuccess
)

export const getUser = createAction<any>(
  USERS.getUser
)

export const getUserError = createAction<any>(
  USERS.getUserError
)

export const getUserSuccess = createAction<any>(
  USERS.getUserSuccess
)
export const getAllUsersLookupSuccess = createAction<any>(
  USERS.getAllUsersLookupSuccess
)




