import { initializeApp } from "firebase/app";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig_prod = {
  apiKey: "AIzaSyDFZBrSxYDRHlPfzSSOfOj3lE0bZhSg9Z4",
  authDomain: "era-dispatch-and-control.firebaseapp.com",
  databaseURL: "https://era-dispatch-and-control.firebaseio.com",
  projectId: "era-dispatch-and-control",
  storageBucket: "era-dispatch-and-control.appspot.com",
  messagingSenderId: "111653508714",
  appId: "1:111653508714:web:70adee1ba81e7e39ee9c13",
  measurementId: "G-8KDS2WQKJZ",
};

const firebaseConfig_dev = {
  apiKey: "AIzaSyAP-t9lprAb3B57YfGGdHTrcbfFE7PM1Os",
  authDomain: "era-dispatch-control-test.firebaseapp.com",
  projectId: "era-dispatch-control-test",
  storageBucket: "era-dispatch-control-test.appspot.com",
  messagingSenderId: "94041346733",
  appId: "1:94041346733:web:05dfd94020182a581f2c24",
  measurementId: "G-B1BNSWE5LZ",
};

const firebaseConfig =
  process.env.REACT_APP_STAGE === "prod"
    ? firebaseConfig_prod
    : firebaseConfig_dev;

export const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
  const vapidKey_prod =
    "BF9dIHl-JA7ctKquIKqHlpgHAt0TjbRfQBSyWP7vdbcikjVgAS37ewx2ybQHevkccyWNqxGZ0v42PrFLn4w-JBo";
  const vapidKey_dev =
    "BB1UUCJ1AHQN4gbjZwKbYLuv4E2Lc-5XmaV4KAKKiMW-2pTh37ZDBON5-NZ7GWYdvs9ZJl3Q5bRGKkydNafTpTQ";
  const vapidKey =
    process.env.REACT_APP_STAGE === "prod" ? vapidKey_prod : vapidKey_dev;

  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      const serviceWorkerRegistration = await getOrRegisterServiceWorker();
      return getToken(messagingResolve, {
        vapidKey,
        serviceWorkerRegistration,
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};
