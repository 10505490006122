import { createAction } from '@reduxjs/toolkit'
import { AMBULANCE } from './types'

export const listAmbulanceRequests = createAction(
  AMBULANCE.listAmbulanceRequests
);

export const listAmbulanceRequestsError = createAction<any>(
  AMBULANCE.listAmbulanceRequestsError
);

export const listAmbulanceRequestsSuccess = createAction<any>(
  AMBULANCE.listAmbulanceRequestsSucccess
);
export const listSingleAmbulanceRequest = createAction<any>(
  AMBULANCE.listSingleAmbulanceRequest
);

export const listSingleAmbulanceRequestError = createAction<any>(
  AMBULANCE.listSingleAmbulanceRequestError
);

export const listSingleAmbulanceRequestSuccess = createAction<any>(
  AMBULANCE.listSingleAmbulanceRequestSucccess
);

export const createAmbulanceRequest = createAction<any>(
  AMBULANCE.createAmbulanceRequest
)

export const createAmbulanceRequestError = createAction<any>(
  AMBULANCE.createAmbulanceRequestError
)

export const createAmbulanceRequestSuccess = createAction<any>(
  AMBULANCE.createAmbulanceRequestSuccess
)
export const updateAmbulanceRequest = createAction<any>(
  AMBULANCE.updateAmbulanceRequest
)

export const updateAmbulanceRequestError = createAction<any>(
  AMBULANCE.updateAmbulanceRequestError
)

export const updateAmbulanceRequestSuccess = createAction<any>(
  AMBULANCE.updateAmbulanceRequestSuccess
)

export const listAmbulanceProviders = createAction(
  AMBULANCE.listAmbulanceProviders
);

export const listAmbulanceProvidersError = createAction<any>(
  AMBULANCE.listAmbulanceProvidersError
);

export const listAmbulanceProvidersSuccess = createAction<any>(
  AMBULANCE.listAmbulanceProvidersSucccess
);

export const updateAmbulancePayment = createAction<any>(
  AMBULANCE.updateAmbulancePayment
);

export const updateAmbulancePaymentError = createAction<any>(
  AMBULANCE.updateAmbulancePaymentError
);

export const updateAmbulancePaymentSuccess = createAction<any>(
  AMBULANCE.updateAmbulancePaymentSucccess
);
export const cancelAmbulancePayment = createAction<any>(
  AMBULANCE.cancelAmbulancePayment
);

export const cancelAmbulancePaymentError = createAction<any>(
  AMBULANCE.cancelAmbulancePaymentError
);

export const cancelAmbulancePaymentSuccess = createAction<any>(
  AMBULANCE.cancelAmbulancePaymentSucccess
);
export const listAmbulanceMetrics = createAction<any>(
  AMBULANCE.listAmbulanceMetrics
);

export const listAmbulanceMetricsError = createAction<any>(
  AMBULANCE.listAmbulanceMetricsError
);

export const listAmbulanceMetricsSuccess = createAction<any>(
  AMBULANCE.listAmbulanceMetricsSuccess
);
export const approveAmbulanceRequest = createAction<any>(
  AMBULANCE.approveAmbulanceRequest
);

export const approveAmbulanceRequestError = createAction<any>(
  AMBULANCE.approveAmbulanceRequestError
);

export const approveAmbulanceRequestSuccess = createAction<any>(
  AMBULANCE.approveAmbulanceRequestSuccess
);
export const updateAmbulanceModal = createAction<any>(
  AMBULANCE.updateAmbulanceModal
);

export const updateAmbulanceModalError = createAction<any>(
  AMBULANCE.updateAmbulanceModalError
);

export const updateAmbulanceModalSuccess = createAction<any>(
  AMBULANCE.updateAmbulanceModalSuccess
);