import { createAction } from '@reduxjs/toolkit'
import { HOSPITALS } from './types'

export const getAllHospital = createAction(
  HOSPITALS.getAllHospital
);

export const getAllHospitalError = createAction<any>(
  HOSPITALS.getAllHospitalError
)

export const getAllHospitalSuccess = createAction<any>(
  HOSPITALS.getAllHospitalSuccess
)

export const createHospital = createAction<any>(
  HOSPITALS.createHospital
)

export const createHospitalError = createAction<any>(
  HOSPITALS.createHospitalError
);

export const createHospitalSuccess = createAction<any>(
  HOSPITALS.createHospitalSuccess,
);

export const getHospital = createAction<any>(
  HOSPITALS.getHospital
)

export const getHospitalError = createAction<any>(
  HOSPITALS.getHospitalError
)

export const getHospitalSuccess = createAction<any>(
  HOSPITALS.getHospitalSuccess
)

export const updateHospital = createAction<any>(
  HOSPITALS.updateHospital
)

export const updateHospitalError = createAction<any>(
  HOSPITALS.updateHospitalError
)

export const updateHospitalSuccess = createAction<any>(
  HOSPITALS.updateHospitalSuccess
)

export const getRecHospitals = createAction<any>(
  HOSPITALS.getRecommendedHospital
)

export const getRecHospitalsError = createAction(
  HOSPITALS.getRecommendedHospitalError
)

export const getRecHospitalsSuccess = createAction<any>(
  HOSPITALS.getRecommendedHospitalSuccess
)
