import { createAction } from "@reduxjs/toolkit";
import { CASES } from "./types";
import { PageSelect } from "../../utils/types";
import { withPayloadType } from "../../utils/withPayload";
import { IAddCase, IUpdateCase } from "../../utils/actionInterface/cases";

export const getAllCases = createAction<any>(CASES.getAllCases);

export const getAllCasesOverview = createAction(CASES.getAllCasesOverview);

export const getCasesDashboard = createAction(CASES.getCasesDashboard);

export const casesPageRange = createAction<any>(CASES.casesPageRange);

export const getAllCasesError = createAction<any>(CASES.getAllCasesError);

export const getAllCasesOverviewError = createAction<any>(
  CASES.getAllCasesOverviewError
);

export const getAllCasesOverviewSuccess = createAction<any>(
  CASES.getAllCasesOverviewSuccess
);

export const getCasesDashboardError = createAction<any>(
  CASES.getCasesDashboardError
);

export const getCasesDashboardSuccess = createAction<any>(
  CASES.getCasesDashboardSuccess
);

export const getAllCasesSuccess = createAction<any>(CASES.getAllCasesSuccess);

export const getAllCasesPage = createAction<PageSelect>(CASES.getAllCasesPage);

export const getAllCasesSuccessPage = createAction<any>(
  CASES.getAllCasesSuccessPage
);

export const getAllPagesCasesError = createAction<any>(
  CASES.getAllPagesCasesError
);

export const addCase = createAction(CASES.addCase, withPayloadType<IAddCase>());

export const addCaseError = createAction<any>(CASES.addCaseError);

export const addCaseSuccess = createAction<any>(CASES.addCaseSuccess);

export const getCase = createAction<any>(CASES.getCase);

export const getCaseError = createAction<any>(CASES.getCaseError);

export const getCaseSuccess = createAction<any>(CASES.getCaseSuccess);

export const getCaseResponders = createAction<any>(CASES.getCaseResponders);

export const getCaseRespondersError = createAction<any>(
  CASES.getCaseRespondersError
);

export const getCaseRespondersSuccess = createAction<any>(
  CASES.getCaseRespondersSuccess
);

export const updateCase = createAction(
  CASES.updateCase,
  withPayloadType<IUpdateCase>()
);

export const updateCaseError = createAction<any>(CASES.updateCaseError);

export const updateCaseSuccess = createAction<any>(CASES.updateCaseSuccess);
export const getIncidentReport = createAction<any>(CASES.getIncidentReport);
export const getIncidentReportError = createAction<any>(
  CASES.getIncidentReportError
);
export const getIncidentReportSuccess = createAction<any>(
  CASES.getIncidentReportSuccess
);
export const getIncidentTypes = createAction(CASES.getIncidentTypes);
export const getIncidentTypesError = createAction<any>(
  CASES.getIncidentTypesError
);
export const getIncidentTypesSuccess = createAction<any>(
  CASES.getIncidentTypesSuccess
);
export const addInquiry = createAction<any>(CASES.addInquiry);
export const addInquiryError = createAction(CASES.addInquiryError);
export const addInquirySuccess = createAction<any>(CASES.addInquirySuccess);
export const updateInquiry = createAction<any>(CASES.updateInquiry);
export const updateInquiryError = createAction(CASES.updateInquiryError);
export const updateInquirySuccess = createAction(CASES.updateInquirySuccess);

export const getReference = createAction<any>(CASES.getReference);

export const getReferenceError = createAction(CASES.getReferenceError);

export const getReferenceSuccess = createAction<any>(CASES.getReferenceSuccess);
export const getSeverity = createAction<any>(CASES.getSeverity);

export const getSeverityError = createAction(CASES.getSeverityError);

export const getSeveritySuccess = createAction<any>(CASES.getSeveritySuccess);

export const getProbableCause = createAction<any>(CASES.getProbableCause);

export const getProbableCauseError = createAction(CASES.getProbableCauseError);

export const getProbableCauseSuccess = createAction<any>(
  CASES.getProbableCauseSuccess
);

export const getDiagnosos = createAction<any>(CASES.getDiagnosis);

export const getDiagnososError = createAction(CASES.getDiagnosisError);

export const getDiagnososSuccess = createAction<any>(CASES.getDiagnosisSuccess);

export const getInquiries = createAction(CASES.getInquiries);

export const getInquiriesError = createAction<any>(CASES.getInquiriesError);

export const getInquiriesSuccess = createAction<any>(CASES.getInquiriesSuccess);

export const getInquiry = createAction<any>(CASES.getInquiry);

export const getInquiryError = createAction<any>(CASES.getInquiryError);

export const getInquirySuccess = createAction<any>(CASES.getInquirySuccess);

export const closeCase = createAction<any>(CASES.closeCase);
export const closeCaseError = createAction(CASES.closeCaseError);
export const closeCaseSuccess = createAction(CASES.closeCaseSuccess);

export const approveCase = createAction<any>(CASES.approveCase);
export const approveCaseError = createAction(CASES.approveCaseError);
export const approveCaseSuccess = createAction(CASES.approveCaseSuccess);
export const getActionTypes = createAction(CASES.getActionTypes);
export const getActionTypesError = createAction(CASES.getActionTypesError);
export const getActionTypesSuccess = createAction<any>(
  CASES.getActionTypesSuccess
);
export const getPartners = createAction(CASES.getPartners);
export const getPartnersError = createAction<any>(CASES.getPartnersError);
export const getPartnersSuccess = createAction<any>(CASES.getPartnersSuccess);
