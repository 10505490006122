import { createReducer } from '@reduxjs/toolkit'
import {
  uploadAvatar, uploadAvatarError, uploadAvatarSuccess
} from '../actions/updateAvatar'

const initialState = {
  loading: false,
  success: {},
  error: null
}

const uploadReducer = createReducer(initialState, (builder) => {
  builder
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .addCase(uploadAvatar, (state) => {
      state.loading = true
    })
    .addCase(uploadAvatarError, (state, action) => {
      state.error = action.payload
    })
    .addCase(uploadAvatarSuccess, (state, action) => {
      state.success = action.payload
    })
})

export default uploadReducer
