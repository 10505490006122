/* eslint-disable import/no-anonymous-default-export */
import {
  put, call, takeLeading
} from 'redux-saga/effects'
import url from '../../api'
import { toast } from 'react-toastify'

import {
   getAllResponders, getAllRespondersError, getAllRespondersSuccess,
   getResponder, getResponderError, getResponderSuccess, 
   updateResponder, updateResponderError, updateResponderSuccess, 
   createResponder, createResponderError, createResponderSuccess,
   getMultipleResponders, getMultipleRespondersError, getMultipleRespondersSuccess,
   notifyResponder, notifyResponderError, notifyResponderSuccess
} from '../actions/respondersActions'

import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

const handleMultipleResponders = (ids: string[]): any => {
  return new Promise((resolve, reject) => {
    const urls = ids.map(id => url.get(`/responders/${id}`))
    Promise.allSettled(urls)
    .then((data: any[]) => {
      let fulfilled = data.filter(item => {
        if (item.status === 'fulfilled') {
          return item;
        }
        return undefined
      })
      if (!fulfilled.length) throw new Error('Responders not found');
      fulfilled = fulfilled.map(item => item.value.data.data);
      resolve(fulfilled)
    }).catch((error: Error) => {
      reject(error);
    })
  })
}

function* getAllRespondersSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))

    const _dispatcher: any = yield call(url.get, `/dispatchers/${_authToken.user_id}`);
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/responders?task=trim`)
        : yield call(url.get, `/responders?task=trim&precinct_id=${precinct_id}`);
    
    let { data } = response.data;
    data = data.map((item: any, index: number) => {
      if(!item.status) {
        item.status = 'offline';
      }
      item.key = index
      item.level_of_training = item?.level_of_training?.toLowerCase()

      return item;
    })

    yield put(getAllRespondersSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(getAllRespondersError(error));
    }
    const message = error?.response?.data?.message;
    toast.error(message)
    yield put(getAllRespondersError(message));
  }
}

function* getMultipleRespondersSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const { ids } = action.payload
    const response: any = yield call(handleMultipleResponders, ids)
    yield put(getMultipleRespondersSuccess(response))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(getMultipleRespondersError())
    }
    toast.error(error)
    yield put(getMultipleRespondersError())
  }
}

function* getRespondersSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const { id } = action.payload
    const response: any = yield call(url.get, `/responders/${id}`);
    const { data } = response.data;
    yield put(getResponderSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(getResponderError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message)
    yield put(getResponderError(message));
  }
}

function* updateRespondersSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const { id, responderInfo } = action.payload
    const response: any = yield call(url.put, `/responders/${id}`, responderInfo);
    const { data } = response.data;
    yield put(updateResponderSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(updateResponderError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message)
    yield put(updateResponderError(message));
  }
}

function* createResponderSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const {responderData, setVisible } = action.payload
    const response: any = yield call(url.post, '/responders',responderData);
    toast.success('Case added sucessfully')
    const { data } = response.data;
    yield put(createResponderSuccess(data));
    yield put(getAllResponders());
    setVisible(false)
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(createResponderError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message)
    yield put(createResponderError(message));
  }
}

function* notifyResponderSaga(action: any): Generator {
  // const { responderData } = action.payload
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    yield call(url.post, '/dispatchers/notify-responders', action.payload);
    toast.success('You request for responders has been successful')
    yield put(notifyResponderSuccess());
    // callback.success()
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(notifyResponderError());
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message)
    yield put(notifyResponderError());
  }
  // callback.error()
}

function* respondersSaga() {
  yield takeLeading(getAllResponders, getAllRespondersSaga);
  yield takeLeading(getResponder, getRespondersSaga);
  yield takeLeading(updateResponder, updateRespondersSaga);
  yield takeLeading(createResponder, createResponderSaga)
  yield takeLeading(getMultipleResponders, getMultipleRespondersSaga)
  yield takeLeading(notifyResponder, notifyResponderSaga)
}

export default respondersSaga