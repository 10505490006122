import { createReducer } from '@reduxjs/toolkit'
import { AmbulanceRequestType } from '../../utils/types';
import {
  createAmbulanceRequest,
  createAmbulanceRequestError,
  createAmbulanceRequestSuccess,
  updateAmbulanceRequest,
  updateAmbulanceRequestError,
  updateAmbulanceRequestSuccess,
  listAmbulanceProviders,
  listAmbulanceProvidersError,
  listAmbulanceProvidersSuccess,
  listAmbulanceRequests,
  listAmbulanceRequestsError,
  listAmbulanceRequestsSuccess,
  listSingleAmbulanceRequest,
  listSingleAmbulanceRequestError,
  listSingleAmbulanceRequestSuccess,
  listAmbulanceMetrics,
  listAmbulanceMetricsError,
  listAmbulanceMetricsSuccess,
  approveAmbulanceRequest,
  approveAmbulanceRequestError,
  approveAmbulanceRequestSuccess,
  updateAmbulanceModal,
  updateAmbulanceModalError,
  updateAmbulanceModalSuccess
} from '../actions/ambulanceActions';

type initialStateType = {
  loading: boolean,
  requests: AmbulanceRequestType[],
  singleRequests: AmbulanceRequestType[],
  providers: [],
  Id: [],
  Metrics: [],
  error: Error | null
}

const initialState: initialStateType = {
  loading: false,
  requests: [],
  singleRequests: [],
  providers: [],
  Id: [],
  Metrics: [],
  error: null,
}

const ambulanceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(listAmbulanceRequests, (state) => {
      state.loading = true
    })
    .addCase(listAmbulanceRequestsError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(listAmbulanceRequestsSuccess, (state, action) => {
      state.loading = false
      state.requests = action.payload
    })
    .addCase(listSingleAmbulanceRequest, (state, action) => {
      state.loading = true
    })
    .addCase(listSingleAmbulanceRequestError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(listSingleAmbulanceRequestSuccess, (state, action) => {
      state.loading = false
      state.singleRequests = action.payload
    })
    .addCase(createAmbulanceRequest, (state, action) => {
      state.loading = true
    })
    .addCase(createAmbulanceRequestError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(createAmbulanceRequestSuccess, (state, action) => {
      state.loading = false
    })
    .addCase(updateAmbulanceRequest, (state, action) => {
      state.loading = true
    })
    .addCase(updateAmbulanceRequestError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateAmbulanceRequestSuccess, (state, action) => {
      state.loading = false
    })
    .addCase(listAmbulanceProviders, (state) => {
      state.loading = true
    })
    .addCase(listAmbulanceProvidersError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(listAmbulanceProvidersSuccess, (state, action) => {
      state.loading = false
      state.providers = action.payload
    })
    .addCase(listAmbulanceMetrics, (state) => {
      state.loading = true
    })
    .addCase(listAmbulanceMetricsError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(listAmbulanceMetricsSuccess, (state, action) => {
      state.loading = false
      state.Metrics = action.payload
    })
    .addCase(approveAmbulanceRequest, (state) => {
      state.loading = true
    })
    .addCase(approveAmbulanceRequestError, (state, action) => {
      state.loading = false
      // state.error = action.payload
    })
    .addCase(approveAmbulanceRequestSuccess, (state, action) => {
      state.loading = false
      // state.Metrics = action.payload
    })
    .addCase(updateAmbulanceModal, (state) => {
      state.loading = true
    })
    .addCase(updateAmbulanceModalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateAmbulanceModalSuccess, (state, action) => {
      state.loading = false
      state.Metrics = action.payload
    })
});

export default ambulanceReducer;
