import { createAction } from '@reduxjs/toolkit'
import { HOSPITAL_TRANSFER } from './types'


export const getAllHospitalTransfer = createAction(
    HOSPITAL_TRANSFER.getAllHospitalTransfer
);


export const getAllHospitalTransferError = createAction<any>(
    HOSPITAL_TRANSFER.getAllHospitalTransferError
)

export const getAllHospitalTransferSuccess = createAction<any>(
    HOSPITAL_TRANSFER.getAllHospitalTransferSuccess
)


export const addHospitalTransfer = createAction<any>(
    HOSPITAL_TRANSFER.addHospitalTransfer
)


export const addHospitalTransferError = createAction<any>(
    HOSPITAL_TRANSFER.addHospitalTransferError
);

export const addHospitalTransferSuccess = createAction<any>(
    HOSPITAL_TRANSFER.addHospitalTransferSuccess,
);

export const getHospitalTransfer = createAction<any>(
    HOSPITAL_TRANSFER.getHospitalTransfer
)

export const getHospitalTransferError = createAction<any>(
    HOSPITAL_TRANSFER.getHospitalTransferError
)

export const getHospitalTransferSuccess = createAction<any>(
    HOSPITAL_TRANSFER.getHospitalTransferSuccess
)

export const updateHospitalTransfer = createAction<any>(
    HOSPITAL_TRANSFER.updateHospitalTransfer
)

export const updateHospitalTransferError = createAction<any>(
    HOSPITAL_TRANSFER.updateHospitalTransferError
)

export const updateHospitalTransferSuccess = createAction<any>(
    HOSPITAL_TRANSFER.updateHospitalTransferSuccess
)
