import { createAction } from '@reduxjs/toolkit'
import { UPLOAD } from './types'




export const uploadAvatar = createAction<any>(
  UPLOAD.uploadAvatar
)

export const uploadAvatarError = createAction<any>(
  UPLOAD.uploadAvatarError
)

export const uploadAvatarSuccess = createAction<any>(
  UPLOAD.uploadAvatarsSuccess
)
