/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from "redux-saga/effects";
import { toast } from "react-toastify";

import url from "../../api";
import {
  getAllCasesError,
  getAllCasesOverviewError,
  getAllCasesSuccess,
  getAllCasesOverviewSuccess,
  getAllCases,
  getAllCasesOverview,
  getCasesDashboardError,
  getCasesDashboardSuccess,
  getCasesDashboard,
  getCaseResponders,
  getCaseRespondersError,
  getCaseRespondersSuccess,
  getCase,
  getCaseError,
  getCaseSuccess,
  getAllCasesSuccessPage,
  getActionTypes,
  getActionTypesError,
  getActionTypesSuccess,
  updateCase,
  updateCaseError,
  updateCaseSuccess,
  addCase,
  addCaseError,
  addCaseSuccess,
  getIncidentReport,
  getIncidentReportError,
  getIncidentReportSuccess,
  getIncidentTypes,
  getIncidentTypesError,
  getIncidentTypesSuccess,
  addInquiry,
  addInquiryError,
  addInquirySuccess,
  updateInquiry,
  updateInquiryError,
  updateInquirySuccess,
  getReference,
  getReferenceError,
  getReferenceSuccess,
  getSeverity,
  getSeverityError,
  getSeveritySuccess,
  getProbableCause,
  getProbableCauseError,
  getProbableCauseSuccess,
  getDiagnosos,
  getDiagnososError,
  getDiagnososSuccess,
  getInquiries,
  getInquiriesError,
  getInquiriesSuccess,
  getInquiry,
  getInquiryError,
  getInquirySuccess,
  closeCase,
  closeCaseError,
  closeCaseSuccess,
  approveCase,
  approveCaseError,
  approveCaseSuccess,
  getAllCasesPage,
  getPartners,
  getPartnersError,
  getPartnersSuccess,
} from "../actions/caseActions";
import route from "../../utils/constants/routes";

import { storeAuthToken } from "../actions/authActions";
import { getAuthToken } from "./authSaga";

const payload = {
  entity: "cases",
  precinct_id: "",
  limit: 20,
  last_key: "null",
  type: "others",
};

function* getCasesSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/cases?limit=10`)
        : yield call(url.get, `/cases?limit=10&precinct_id=${precinct_id}`);

    let { data } = response.data;
    data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = "low";
      }
      item.key = index;
      return item;
    });
    yield put(getAllCasesSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      toast.error("Please check your network connection and try again");
      yield put(getAllCasesError(error));
    }
    const message = error?.response?.data?.message;
    yield put(getAllCasesError(message));
  }
}

function* getCasesOverviewSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/cases?limit=0`)
        : yield call(url.get, `/cases?limit=0&precinct_id=${precinct_id}`);

    let { data } = response.data;
    data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = "low";
      }
      item.key = index;
      return item;
    });
    yield put(getAllCasesOverviewSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      toast.error("Please check your network connection and try again");
      yield put(getAllCasesOverviewError(error));
    }
    const message = error?.response?.data?.message;

    yield put(getAllCasesOverviewError(message));
  }
}

function* getCasesDashboardSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/cases?task=trim&limit=10`)
        : yield call(
            url.get,
            `/cases?task=trim&limit=10&precinct_id=${precinct_id}`
          );

    let { data } = response.data;
    data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = "low";
      }
      item.key = index;
      return item;
    });
    yield put(getCasesDashboardSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      toast.error("Please check your network connection and try again");
      yield put(getCasesDashboardError(error));
    }
    const message = error?.response?.data?.message;

    yield put(getCasesDashboardError(message));
  }
}

function* getCasesPageSaga(action): Generator {
  const { payload } = action;
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const response: any = yield call(url.post, `/entities`, payload);
    let { data } = response;
    // data = data.map((item: any, index: number) => {
    //   if (!item.severity) {
    //     item.severity = "low";
    //   }
    //   item.key = index;
    //   return item;
    // });
    yield put(getAllCasesSuccessPage(data.pages));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      toast.error("Please check your network connection and try again");
      yield put(getAllCasesError(error));
    }
    const message = error?.response?.data?.message;

    yield put(getAllCasesError(message));
  }
}

function* getInquiriesSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/inquiry?limit=0`)
        : yield call(url.get, `/inquiry?limit=0&precinct_id=${precinct_id}`);

    let { data } = response.data;
    yield put(getInquiriesSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getInquiriesError(error));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getInquiriesError(message));
  }
}

function* getIncidentTypesSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.get, "/references/incident-types");
    let inicidentTypes = response?.data?.data?.inicidentTypes;
    yield put(getIncidentTypesSuccess(inicidentTypes));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getIncidentTypesError(error));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getIncidentTypesError(message));
  }
}

function* getSingleInquirySaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id } = action.payload;
    const response: any = yield call(url.get, `/inquiry/${id}`);
    const { data } = response.data;
    yield put(getInquirySuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getInquiryError(error));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getIncidentReportError(message));
  }
}

function* getCaseSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id } = action.payload;
    const response: any = yield call(url.get, `/cases/${id}`);
    const { data } = response.data;
    yield put(getCaseSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getCaseError(error));
      yield put(getCaseSuccess({}));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getCaseError(message));
  }
}

function* getCaseRespondersSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id } = action.payload;
    const response: any = yield call(url.get, `/cases/${id}/responders`);
    const { data } = response.data;
    yield put(getCaseRespondersSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getCaseRespondersError(error));
      yield put(getCaseRespondersSuccess({}));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getCaseRespondersError(message));
  }
}

function* updateCaseSaga(action: ReturnType<typeof updateCase>): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id, caseData, callback } = action.payload;

    let case_status = caseData.case_status;
    if (action.payload.status) {
      case_status = action.payload.status;
    }
    const response: any = yield call(url.put, `/cases/${id}`, {
      ...caseData,
      case_status,
    });
    const { data } = response.data;
    toast.success("Case updated sucessfully");
    yield put(updateCaseSuccess(data));

    // yield put(getAllCases(payload));
    callback && callback?.onSuccess && callback.onSuccess(data);
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateCaseError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updateCaseError(message));
  }
}

function* createCaseSaga(action: ReturnType<typeof addCase>): Generator {
  const { caseData, callback } = action.payload;
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.post, "/cases", caseData);
    const { data } = response.data;
    yield put(addCaseSuccess(data));
    toast.success("Case added sucessfully");
    callback?.onSuccess && callback?.onSuccess(data);
  } catch (error: Error | any) {
    callback?.onError && callback.onError(error);
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(addCaseError(error));
    }
    if (error.response) {
      const {
        data: { message },
      } = error.response;
      toast.error(message);
      yield put(addCaseError(message));
    } else {
      console.log("=============error=======================");
      console.log("life", error);
      console.log("====================================");
    }
  }
}

function* getIncidentReportSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id } = action.payload;
    const response: any = yield call(url.get, `/incident-reports/${id}`);
    const { data } = response.data;
    yield put(getIncidentReportSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getIncidentReportError(error));
    }
    const {
      data: { message },
    } = error.response;

    yield put(getIncidentReportError(message));
  }
}

function* addInquirySaga(action: any): Generator {
  const { inquiryData, history, callback } = action.payload;
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.post, `/inquiry`, inquiryData);
    const { data } = response.data;

    yield put(addInquirySuccess(data));
    if (inquiryData.nature_of_inquiry === "non-emergency") {
      callback?.onSuccess && callback?.onSuccess();
      history.push(route.inquiries);
      toast.success("Inquiry created successfully");
    } else {
      callback?.onSuccess && callback?.onSuccess(data);
    }
  } catch (error: Error | any) {
    callback?.onError && callback.onError(error);
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(addInquiryError());
    }
    if (error.response) {
      const {
        data: { message },
      } = error.response;
      toast.error(message);
      yield put(addCaseError(message));
    } else {
      console.log("=============error=======================");
      console.log(error);
      console.log("====================================");
    }
    yield put(addInquiryError());
  }
}

function* updateInquirySaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id, inquiryDatas } = action.payload;
    const response: any = yield call(url.put, `/inquiry/${id}`, inquiryDatas);

    const { data } = response;
    toast.success(data.message);
    yield put(updateInquirySuccess());
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateInquiryError());
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updateInquiryError());
  }
}

function* getReferenceSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(
      url.get,
      "/references/provisional-diagnosis"
    );
    const { data } = response.data;
    yield put(getReferenceSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getReferenceError());
    }
    const { logout } = action.payload;
    if (error === "No current user") {
      return logout();
    }
    console.log("==========Error==========================");
    console.log("", error);
    console.log("====================================");

    yield put(getReferenceError());
  }
}
function* getActionTypeSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.get, "/references/action-taken");

    const { data } = response.data;
    yield put(getActionTypesSuccess(data.caseActionTaken));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getActionTypesError());
    }
    const { logout } = action.payload;
    if (error === "No current user") {
      return logout();
    }
    console.log("==========Error==========================");
    console.log("", error);
    console.log("====================================");

    yield put(getActionTypesError());
  }
}
function* getSeveritySaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.get, "/references/reference");
    const { references } = response.data.data;
    yield put(getSeveritySuccess(references));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getSeverityError());
    }
    const { logout } = action.payload;
    if (error === "No current user") {
      return logout();
    }
    console.log("==========Error==========================");
    console.log("", error);
    console.log("====================================");

    yield put(getSeverityError());
  }
}

function* getProbableCauseSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { code } = action.payload;
    const response: any = yield call(
      url.get,
      `/references/${code}/probable-cause`
    );
    const datas = response.data.data;
    yield put(getProbableCauseSuccess(datas?.probableCause));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getProbableCauseError());
    }
    const { logout } = action.payload;
    if (error === "No current user") {
      return logout();
    }
    console.log("==========Error==========================");
    console.log("", error);
    console.log("====================================");

    yield put(getProbableCauseError());
  }
}

function* getDiagnosisSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(
      url.post,
      "/recommendation/diagnosis",
      action.payload
    );
    const { data } = response.data;
    yield put(getDiagnososSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getDiagnososError());
    }

    console.log("==========Error==========================");
    console.log(error);
    console.log("====================================");

    yield put(getDiagnososError());
  }
}

function* closeCaseSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { case_id, dispatcher_id, callback, requestData } = action.payload;
    const response: any = yield call(url.post, `/cases/${case_id}/close`, {
      dispatcher_id,
      ...requestData,
    });
    const { status, message } = response.data;
    yield put(closeCaseSuccess());
    toast.success(status === "success" ? "Case closed sucessfully" : message);
    callback();

    getCasesSaga(payload);
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(closeCaseError());
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(closeCaseError());
  }
}

function* approveCaseSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { case_id, callback } = action.payload;
    const response: any = yield call(url.post, `/cases/${case_id}/approve`);
    const { status, message } = response.data;
    yield put(approveCaseSuccess());
    toast.success(status === "success" ? "Case approved sucessfully" : message);
    callback();

    getCasesSaga(payload);
    // updateCaseSaga(action)
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(approveCaseError());
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(approveCaseError());
  }
}
function* getPartnersSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.get, "/references/reference");
    const partners = response?.data?.data?.references?.partners;
    const partnerData = partners.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });
    yield put(getPartnersSuccess(partnerData));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getPartnersError(error));
    }
    // const {
    //   data: { message },
    // } = error.response;

    // yield put(getIncidentTypesError(message));
  }
}

function* casesSaga() {
  yield takeLeading(getAllCases, getCasesSaga);
  yield takeLeading(getAllCasesOverview, getCasesOverviewSaga);
  yield takeLeading(getCasesDashboard, getCasesDashboardSaga);
  yield takeLeading(getAllCasesPage, getCasesPageSaga);
  yield takeLeading(getCase, getCaseSaga);
  yield takeLeading(getCaseResponders, getCaseRespondersSaga);
  yield takeLeading(updateCase, updateCaseSaga);
  yield takeLeading(addCase, createCaseSaga);
  yield takeLeading(addInquiry, addInquirySaga);
  yield takeLeading(updateInquiry, updateInquirySaga);
  yield takeLeading(getIncidentReport, getIncidentReportSaga);
  yield takeLeading(getReference, getReferenceSaga);
  yield takeLeading(getSeverity, getSeveritySaga);
  yield takeLeading(getProbableCause, getProbableCauseSaga);
  yield takeLeading(getDiagnosos, getDiagnosisSaga);
  yield takeLeading(getInquiries, getInquiriesSaga);
  yield takeLeading(getInquiry, getSingleInquirySaga);
  yield takeLeading(closeCase, closeCaseSaga);
  yield takeLeading(approveCase, approveCaseSaga);
  yield takeLeading(getIncidentTypes, getIncidentTypesSaga);
  yield takeLeading(getActionTypes, getActionTypeSaga);
  yield takeLeading(getPartners, getPartnersSaga);
}

export default casesSaga;
