import { createReducer } from '@reduxjs/toolkit'
import {
  getAllHospital, getAllHospitalError, getAllHospitalSuccess, getHospital, getHospitalError, getHospitalSuccess, createHospital, createHospitalError, createHospitalSuccess, updateHospital, updateHospitalError, updateHospitalSuccess, getRecHospitalsSuccess
} from '../actions/hospitalActions'

interface IinitialState {
  loading: boolean,
  hospitals: { [key: string]: any }[],
  recommendedHospitals: { [key: string]: any },
  singleHospital: { [key: string]: any },
  error: string | any,
}

const initialState: IinitialState = {
  loading: false,
  hospitals: [],
  singleHospital: [],
  error: null,
  recommendedHospitals: {}
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllHospital, (state) => {
      state.loading = true
    })
    .addCase(getAllHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getAllHospitalSuccess, (state, action) => {
      state.loading = false
      state.hospitals = action.payload
    })
    .addCase(getHospital, (state, action) => {
      state.loading = true
    })
    .addCase(getHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getHospitalSuccess, (state, action) => {
      state.loading = false
      state.singleHospital = action.payload
    })
    .addCase(updateHospital, (state, action) => {
      state.loading = true
    })
    .addCase(updateHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateHospitalSuccess, (state, action) => {
      state.loading = false
    })
    .addCase(createHospital, (state, action) => {
      state.loading = true
    })
    .addCase(createHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(createHospitalSuccess, (state) => {
      state.loading = false
    })
    .addCase(getRecHospitalsSuccess, (state, action) => {
      state.loading = false
      state.recommendedHospitals = action.payload
    })
})

export default caseReducer