import { combineReducers } from 'redux';
import cases from './casesReducer'
import hospitalTransfer from './hospitalTransferReducer'
import auth from './authReducer'
import responders from './respondersReducer'
import hospital from './hospitalReducer'
import users from './userReducer'
import dispatcher from './dispatcherReducer'
import upload from './uploadAvatar'
import ambulance from './ambulanceReducer'

const rootReducer = combineReducers({
    cases,
    hospital,
    hospitalTransfer,
    auth,
    responders, 
    users,
    dispatcher,
    upload,
    ambulance
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;