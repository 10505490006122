export type PatientType = {
  first_name?: string;
  last_name?: string;
  gender?: string;
  age_group?: string;
  pre_existing_condition?: string;
  allergies?: string;
  medication?: string;
};
export type VitalsType = {
  pulse?: string;
  time?: any;
  blood_pressure?: string;
  pulse_ox?: string;
  respiratory?: string;
};

export const PatientData: PatientType = {
  first_name: "",
  last_name: "",
  gender: "",
  age_group: "",
  pre_existing_condition: "",
  allergies: "",
  medication: "",
};
export type PageSelect = {
  start?: number;
  end?: number;
};

export type Payload = {
  entity: string;
  precinct_id: string;
  limit: number;
  last_key: any;
  type: string;
};

export type CaseType = {
  address_line_1: string;
  address_line_2?: string;
  caller_first_name?: string;
  caller_last_name?: string;
  caller_phone_number: any;
  caller_id: string;
  case_close_time?: string;
  case_open_time?: string | number;
  case_id?: string;
  case_status?: string;
  closing_reason?: string;
  close_dispatcher_id?: string;
  country?: string;
  incident_type?: string;
  incident_description?: string;
  incident_summary?: string;
  landmark?: string;
  location_latitude?: number;
  location_longitude?: number;
  notes?: string;
  number_of_patients: number;
  open_dispatcher_id?: string;
  partner_case?: Boolean;
  partner_name?: string;
  probable_cause?: string;
  responders?: any;
  responded_to?: any;
  responded_at?: any;
  severity?: string;
  state?: string;
  approved_by_dispatcher?: boolean;
  approved_by_dispatcher_at?: string | number;
  third_party_check?: boolean;
  third_party_notes?: string;
  third_party_organization?: string;
  status?: string;
  patients?: PatientType[];
  security_details?: string;
  whatsapp_pin?: string;
  provisional_diagnosis?: string;
  category_of_emergency?: string;
  action_taken?: string[];
  details_of_action_taken?: string;
  inquiry_id?: string;
  dispatcher_id?: string;
  time_lapsed?: string | number;
  additional_information?: any;
  responder_recommendation?: any;
  user_can_talk?: Boolean;
  source?: string;
};

export const CaseTypeData: CaseType = {
  address_line_1: "",
  address_line_2: "",
  caller_first_name: "",
  caller_last_name: "",
  caller_phone_number: "",
  caller_id: "",
  country: "",
  incident_description: "",
  incident_summary: "",
  incident_type: "",
  landmark: "",
  location_latitude: 0,
  location_longitude: 0,
  user_can_talk: false,
  number_of_patients: 1,
  probable_cause: "",
  severity: "",
  state: "",
  third_party_check: false,
  third_party_notes: "",
  third_party_organization: "",
  patients: [PatientData],
  security_details: "",
  whatsapp_pin: "",
  category_of_emergency: "",
  action_taken: [],
  details_of_action_taken: "",
  additional_information: {},
  source: "",
};

export type IncidentResponseType = {
  address_line_1: string;
  address_line_2?: string;
  ailments?: [];
  allergies?: [];
  case_id?: string;
  city?: string;
  country?: string;
  date_of_birth?: string;
  first_name?: string;
  id_number?: string;
  id_type?: string;
  incident_report_id?: string;
  last_name?: string;
  medication_history?: [];
  nationality?: string;
  phone_number?: string;
  provisional_diagnosis?: string;
  responsible_party_first_name?: string;
  responsible_party_last_name?: string;
  responsible_party_phone_number?: string;
  responsible_party_relationship?: string;
  severity?: string;
  sex?: string;
  state?: string;
  user_id?: string;
  vital_checks?: VitalsType;
};
export const IncidentReportTypeData: IncidentResponseType = {
  address_line_1: "",
  address_line_2: "",
  ailments: [],
  allergies: [],
  case_id: "",
  city: "",
  country: "",
  date_of_birth: "",
  first_name: "",
  id_number: "",
  id_type: "",
  incident_report_id: "",
  last_name: "",
  medication_history: [],
  nationality: "",
  phone_number: "",
  provisional_diagnosis: "",
  responsible_party_first_name: "",
  responsible_party_last_name: "",
  responsible_party_phone_number: "",
  responsible_party_relationship: "",
  severity: "",
  sex: "",
  state: "",
  user_id: "",
  vital_checks: {},
};

export type ResponderType = {
  address_line_1: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  case_close_time?: any;
  case_open_time?: string | number;
  location_latitude?: number;
  location_longitude?: number;
  level_of_training?: string;
  date_of_birth?: any;
  responder_type?: string;
  responder_group?: string;
  responder_id?: string;
  email?: string;
  profile_image_url?: string;
  status?: string;
  state?: string;
  precinct_name: string;
};

export const ResponderTypeData: ResponderType = {
  address_line_1: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  case_close_time: {},
  // case_open_time: "" | 0,
  location_latitude: 0,
  location_longitude: 0,
  level_of_training: "",
  date_of_birth: {},
  responder_type: "",
  responder_id: "",
  email: "",
  profile_image_url: "",
  status: "",
  state: "",
  precinct_name: "",
};

export type CaseRespondersListType = {
  case_id?: string;
  createdAt: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  profile_image_url?: string;
  responder_id: string;
  location_latitude: number;
  location_longitude: number;
  responder_status: {
    [x: string]: any;
    location_longitude: number;
    status: string;
    timestamp: string;
    location_latitude: number;
  };
  online_status?: string;
  state?: string;
  precinct?: string;
  precinct_name?: string;
  responder_type: string;
  status?: string;
};

export type CaseRespondersType = {
  case_responder: any;
  case_responders: CaseRespondersListType[];
  count: number;
};

export type HospitalType = {
  address_line_1: string;
  hospital_name?: string;
  contact?: string;
  contact_person?: string;
  case_close_time?: any;
  case_open_time?: string | number;
  location_latitude?: number;
  location_longitude?: number;
  capacity?: number;
  hospital_id?: string;
  city?: string;
  country?: string;
  email?: string;
  hospital_code?: number | string;
  logo_url?: string;
  operational_days?: number | null;
  operational_hours?: number | null;
  ownership_type?: string;
  registration_datetime?: any;
  registration_number?: number | string;
  state?: string;
  website?: string;
  status?: string;
};

export type AmbulanceRequestType = {
  dropoff_location?: string;
  dropoff_location_latitude?: number;
  dropoff_location_longitude?: number;
  pickup_location_latitude?: number;
  pickup_location_longitude?: number;
  status?: string;
  patient_gender?: string;
  createdAt?: any;
  patient_last_name?: string;
  ambulance_request_id?: string;
  patient_age?: number;
  requires_oxygen?: boolean;
  last_name?: string;
  covid_case?: boolean;
  request_category?: string;
  patient_first_name?: string;
  first_name?: string;
  pickup_location?: string;
  phone_number?: string;
  request_type?: string;
  responded_to?: boolean;
  api_user_id?: string;
};

export type AmbulanceRequestMetricsType = {
  total: number;
  open: number;
  "in-progress": number;
  closed: number;
  cancelled: number;
};

export type CaseMetricsType = {
  total: number;
  open: number;
  "in-progress": number;
  closed: number;
  cancelled: number;
};
//{total: 490, open: 360, in-progress: 1, cancelled: 0, closed: 119}
//total: 285, open: 191, in-progress: 13, closed: 38, cancelled: 43
