import { createAction } from '@reduxjs/toolkit'
import { RESPONDERS } from './types'


export const getAllResponders = createAction(
  RESPONDERS.getAllResponders
);

export const getAllRespondersError = createAction<any>(
  RESPONDERS.getAllRespondersError
)

export const getAllRespondersSuccess = createAction<any>(
  RESPONDERS.getAllRespondersSuccess
)

export const getResponder = createAction<any>(
  RESPONDERS.getResponder
)

export const getResponderError = createAction<any>(
  RESPONDERS.getResponderError
)

export const getResponderSuccess = createAction<any>(
  RESPONDERS.getResponderSuccess
)

export const getMultipleResponders = createAction<any>(
  RESPONDERS.getMultipleResponders
)

export const getMultipleRespondersError = createAction(
  RESPONDERS.getMultipleRespondersError
)

export const getMultipleRespondersSuccess = createAction<any>(
  RESPONDERS.getMultipleRespondersSuccess
)

export const updateResponder = createAction<any>(
  RESPONDERS.updateResponder
)

export const updateResponderError = createAction<any>(
  RESPONDERS.updateResponderError
)

export const updateResponderSuccess = createAction<any>(
  RESPONDERS.updateResponderSuccess
)

export const createResponder = createAction<any>(
  RESPONDERS.createResponder
)

export const createResponderError = createAction<any>(
  RESPONDERS.createResponderError
)

export const createResponderSuccess = createAction<any>(
  RESPONDERS.createResponderSuccess
)

export const notifyResponder = createAction<any>(
  RESPONDERS.notifyResponder
)

export const notifyResponderError = createAction(
  RESPONDERS.notifyResponderError
)


export const notifyResponderSuccess = createAction(
  RESPONDERS.notifyResponderSuccess
)

