/* eslint-disable import/no-anonymous-default-export */
import {
  put, call, takeLeading
} from 'redux-saga/effects'
import { toast } from 'react-toastify'
import url from '../../api'
import {
  uploadAvatar, uploadAvatarError, uploadAvatarSuccess
} from '../actions/updateAvatar'
import { updatedispatcher } from '../../store/actions/dispatcherActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* uploadAvatarSaga(action : any) : Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const {
      entityData, mime, image, user_id
    } = action.payload
    const response: any = yield call(url.post, '/upload/image', {mime, image})
    const { data, message } = response.data
      const d = { ...entityData, profile_image_url: data.imageURL }
      yield put(updatedispatcher ({ user_id: user_id, dispatchData: d }))
      toast.success(message);
    yield put(uploadAvatarSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      console.log("Please check your network connection and try again");
      yield put(uploadAvatarError(error))
    }
  }
}

function* uploadSaga() {
  yield takeLeading(uploadAvatar, uploadAvatarSaga)
}

export default uploadSaga
