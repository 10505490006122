const ROUTES = {
  login: "/login",
  adminLogin: "/admin-login",
  dispatchLogin: "/dispatch-login",
  setPassword: "/set-password",
  residencyInfo: "/residency-info",
  phoneVerification: "/phone-verification",
  home: "/",
  ambulanceRequest: "/ambulance-requests",
  ambulanceProviders: "/ambulance-providers",
  cases: "/cases",
  users: "/users",
  map: "/map",
  reportcase: "/new-case",
  secondary: "/cases/secondary/:case_id",
  updateCase: "/cases/update/:case_id",
  hospitals: "/hospitals",
  vehicles: "/vehicles",
  inquiries: "/inquiries",
  signUp: "/sign-up",
  responders: "/responders",
  createResponder: "/responders/create",
  forgotPassword: "/forgot-password",
  changePassword: "/forgotPassword/change_password",
  profile: "/settings",
  notFound: "*",
  notification: "/notifications",
};

export default ROUTES;
