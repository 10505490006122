/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import url from '../../api'
import { 
    getAllUsers, getAllUsersError, getAllUsersLookup, getAllUsersSuccess,
    getUser, getUserError, getUserSuccess, getAllUsersLookupSuccess
} from '../actions/usersActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* getUsersSaga(): Generator {
    try {
        const _authToken: any = yield call(getAuthToken)
        yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
        const response: any = yield call(url.get, `/users?limit=${10000000}`)
        let { data } = response.data;
        data = data.users.map((item: any, index: number) => {
            item.key = index
            return item
        })
        yield put(getAllUsersSuccess(data));
    } catch (error: Error | any) {
        if (error.toString() === 'Error: Network Error') {
           console.log("Please check your network connection and try again");
            yield put(getAllUsersError(error));
        }
        const {
            data: { message },
        } = error.response
        toast.error(message)
        
        yield put(getAllUsersError(message))
    }
}
function* getUsersLookupSaga(): Generator {
    try {
        const _authToken: any = yield call(getAuthToken)
        yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
        const response: any = yield call(url.get, `/users/lookup`)
        let { data } = response.data;
        data = data.users.map((item: any, index: number) => {
            item.key = index
            return item
        })
        yield put(getAllUsersLookupSuccess(data));
    } catch (error: Error | any) {
        if (error.toString() === 'Error: Network Error') {
           console.log("Please check your network connection and try again");
            yield put(getAllUsersError(error));
        }
        const {
            data: { message },
        } = error.response
        toast.error(message)
        
        yield put(getAllUsersError(message))
    }
}

function* getUserSaga(action: any): Generator {
    try {
        const _authToken: any = yield call(getAuthToken)
        yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
        const { id } = action.payload
        const response: any = yield call(url.get, `/users/${id}`)
        const { data } = response.data
        yield put(getUserSuccess(data))
    } catch (error: Error | any) {
        if (error.toString() === 'Error: Network Error') {
           console.log("Please check your network connection and try again");
            yield put(getUserError(error));
        }
        const {
            data: { message },
        } = error.response
        toast.error(message)

        yield put(getUserError(message))
    }
}



function* usersSaga() {
    yield takeLeading(getAllUsers, getUsersSaga)
    yield takeLeading(getAllUsersLookup, getUsersLookupSaga)
    yield takeLeading(getUser, getUserSaga)
   
}

export default usersSaga