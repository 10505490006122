/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from "@reduxjs/toolkit";
import {
  getAllCases,
  getAllCasesError,
  getAllCasesSuccess,
  getAllCasesOverview,
  getAllCasesOverviewError,
  getAllCasesOverviewSuccess,
  getCasesDashboard,
  getCasesDashboardError,
  getCasesDashboardSuccess,
  getAllCasesSuccessPage,
  getAllPagesCasesError,
  getCase,
  getCaseError,
  getCaseSuccess,
  getActionTypes,
  getActionTypesError,
  getActionTypesSuccess,
  getIncidentReport,
  getIncidentReportError,
  getIncidentReportSuccess,
  getIncidentTypes,
  getIncidentTypesError,
  getIncidentTypesSuccess,
  updateCase,
  updateCaseError,
  updateCaseSuccess,
  closeCase,
  closeCaseError,
  closeCaseSuccess,
  approveCase,
  approveCaseError,
  approveCaseSuccess,
  addInquirySuccess,
  addCaseSuccess,
  getReferenceSuccess,
  getReference,
  getProbableCauseSuccess,
  getProbableCause,
  getSeverity,
  getSeveritySuccess,
  getDiagnososSuccess,
  getDiagnososError,
  getInquiries,
  getInquiriesError,
  getInquiriesSuccess,
  getInquiry,
  getInquiryError,
  getInquirySuccess,
  updateInquiry,
  updateInquiryError,
  updateInquirySuccess,
  getAllCasesPage,
  // casesPageRange,
  getPartnersError,
  getPartnersSuccess,
  getCaseResponders,
  getCaseRespondersError,
  getCaseRespondersSuccess,
} from "../actions/caseActions";

import {
  CaseRespondersType,
  CaseType,
  CaseTypeData,
  // PageSelect,
  Payload,
  // ResponderType,
} from "../../utils/types";

interface IinitialState {
  loading: boolean;
  loadingPages: boolean;
  cases: CaseType[];
  caseResponders: CaseRespondersType;
  singleCase: CaseType;
  incidentReport: { [key: string]: any }[];
  error: string | any;
  inquiry: { [key: string]: any };
  refrences: { [key: string]: any };
  severity: { [key: string]: any };
  probableCause: { [key: string]: any };
  addedCase: { [key: string]: any } | null;
  diagnosis: { [key: string]: any } | null;
  inquiries: { [key: string]: any }[];
  incidentType: { [key: string]: any }[];
  singleinquiry: { [key: string]: any };
  actionType: { [key: string]: any };
  // pageRange: PageSelect
  payload: Payload;
  payloadPages: Payload;
  casesPages: { [key: string]: any }[];
  payloadInquiries: Payload;
  partner: {
    label: string;
    value: string;
  }[];
}

const initialState: IinitialState = {
  loading: false,
  loadingPages: false,
  cases: [],
  caseResponders: null,
  singleCase: CaseTypeData,
  incidentReport: [],
  error: null,
  inquiry: {},
  refrences: [],
  severity: [],
  probableCause: [],
  addedCase: {},
  diagnosis: null,
  inquiries: [],
  incidentType: [],
  singleinquiry: {},
  actionType: [],
  payload: {
    entity: "cases",
    precinct_id: "",
    limit: 10,
    last_key: null,
    type: "others",
  },
  payloadPages: {
    entity: "cases",
    precinct_id: "",
    limit: 50,
    last_key: null,
    type: "initial",
  },
  casesPages: [],
  payloadInquiries: {
    entity: "inquiries",
    precinct_id: "",
    limit: 20,
    last_key: "null",
    type: "others",
  },
  partner: [],
};

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllCases, (state, action) => {
      state.loading = true;
    })
    .addCase(getCasesDashboard, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllCasesOverview, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllCasesPage, (state, action) => {
      state.loadingPages = true;
    })
    .addCase(getAllCasesError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getAllCasesSuccess, (state, action) => {
      state.loading = false;
      state.cases = action.payload.data;
      state.payload.last_key = action.payload.last_key;
    })
    .addCase(getAllCasesOverviewError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getCasesDashboardError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getCasesDashboardSuccess, (state, action) => {
      state.loading = false;
      state.cases = action.payload;
    })
    .addCase(getAllCasesOverviewSuccess, (state, action) => {
      state.loading = false;
      state.cases = action.payload;
    })
    .addCase(getAllCasesSuccessPage, (state, action) => {
      state.loadingPages = false;
      state.casesPages = action.payload;
    })
    .addCase(getCase, (state, action) => {
      state.loading = true;
    })
    .addCase(getCaseResponders, (state, action) => {
      state.loading = true;
    })
    .addCase(getCaseRespondersSuccess, (state, action) => {
      state.loading = false;
      state.caseResponders = action.payload;
    })
    .addCase(getCaseRespondersError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getAllPagesCasesError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getCaseError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getCaseSuccess, (state, action) => {
      state.loading = false;
      state.singleCase = action.payload;
    })
    .addCase(addCaseSuccess, (state, action) => {
      state.loading = false;
      state.addedCase = action.payload;
    })
    .addCase(updateCase, (state, action) => {
      state.loading = true;
    })
    .addCase(updateCaseError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateCaseSuccess, (state) => {
      state.loading = false;
    })
    .addCase(updateInquiry, (state, action) => {
      state.loading = true;
    })
    .addCase(updateInquiryError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateInquirySuccess, (state) => {
      state.loading = false;
    })
    .addCase(closeCase, (state, action) => {
      state.loading = true;
    })
    .addCase(closeCaseError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(closeCaseSuccess, (state) => {
      state.loading = false;
    })
    .addCase(approveCase, (state, action) => {
      state.loading = true;
    })
    .addCase(approveCaseError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(approveCaseSuccess, (state) => {
      state.loading = false;
    })
    .addCase(getIncidentReport, (state) => {
      state.loading = true;
    })
    .addCase(getIncidentReportError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getIncidentReportSuccess, (state, action) => {
      state.loading = false;
      state.incidentReport = action.payload;
    })
    .addCase(getIncidentTypes, (state) => {
      state.loading = true;
    })
    .addCase(getIncidentTypesError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getIncidentTypesSuccess, (state, action) => {
      state.loading = false;
      state.incidentType = action.payload;
    })
    .addCase(addInquirySuccess, (state, action) => {
      state.loading = false;
      state.inquiry = action.payload;
    })
    .addCase(getReference, (state, action) => {
      state.loading = false;
      // state.refrences = action.payload;
    })
    .addCase(getReferenceSuccess, (state, action) => {
      state.loading = false;
      state.refrences = action.payload;
    })
    .addCase(getSeverity, (state, action) => {
      state.loading = false;
      // state.refrences = action.payload;
    })
    .addCase(getSeveritySuccess, (state, action) => {
      state.loading = false;
      state.severity = action.payload;
    })
    .addCase(getProbableCause, (state, action) => {
      state.loading = false;
      // state.refrences = action.payload;
    })
    .addCase(getProbableCauseSuccess, (state, action) => {
      state.loading = false;
      state.probableCause = action.payload;
    })
    .addCase(getDiagnososSuccess, (state, action) => {
      state.loading = false;
      state.diagnosis = action.payload;
    })
    .addCase(getDiagnososError, (state) => {
      state.loading = false;
    })
    .addCase(getInquiries, (state) => {
      state.loading = true;
    })
    .addCase(getInquiriesError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getInquiriesSuccess, (state, action) => {
      state.loading = false;
      state.inquiries = action.payload.inquiries;
      state.payloadInquiries.last_key = action.payload.last_key;
    })
    .addCase(getInquiry, (state) => {
      state.loading = true;
    })
    .addCase(getInquiryError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getInquirySuccess, (state, action) => {
      state.loading = false;
      state.singleinquiry = action.payload;
    })
    .addCase(getActionTypes, (state) => {
      state.loading = true;
    })
    .addCase(getActionTypesError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getActionTypesSuccess, (state, action: any) => {
      state.loading = false;
      state.actionType = action.payload;
    })
    .addCase(getPartnersError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getPartnersSuccess, (state, action) => {
      state.loading = false;
      state.partner = action.payload;
    });
});

export default caseReducer;
