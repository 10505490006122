/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from '@reduxjs/toolkit'
import 
    {
     signinError, signinSuccess,
     createUserError, createUserSuccess,
     logoutError, logoutSuccess,
     resetPasswordError, resetPasswordSuccess,
     completeNewPasswordError, completeNewPasswordSuccess,
     setNewPasswordError, setNewPasswordSuccess,
     setAuthStatus, storeAuthToken,
     setAuthentication, setLoading, setDispatchInfo
    }
 from '../actions/authActions'
 interface IinitialState {
     isLoading: boolean,
     isAuthenticated: boolean,
     userDetails: {[key: string]: any},
     isError: boolean,
     createdUserInfo: { [key: string]: any },
     paswordReset: boolean,
     isPasswordChanged: boolean,
     token: string | any,
     issuer: string | any,
     dispatchInfo: { [key: string]: any }
 }

const initialState: IinitialState = {
    isLoading: false,
    isAuthenticated: false,
    userDetails: {},
    isError: false,
    createdUserInfo: {},
    paswordReset: false,
    isPasswordChanged: false,
    token: '',
    issuer: '',
    dispatchInfo: {}
}

const caseReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(signinError, (state) => {
            state.isError = true
        })
        .addCase(signinSuccess, (state, action) => {            
            state.isError = false
            state.userDetails = action.payload
        })
        .addCase(setAuthentication, (state, action) => {
            state.isAuthenticated = action.payload
        })
        .addCase(createUserError, (state, action) => {
            state.isError = action.payload
        })
        .addCase(createUserSuccess, (state, action) => {
            state.isError = false
            state.createdUserInfo = action.payload
        })
        .addCase(logoutError, (state) => {
            state.isError = true
        })
        .addCase(logoutSuccess, (state) => {
            state.isError = false
            state.userDetails = {}
            state.isAuthenticated = false
        })
        .addCase(resetPasswordError, (state, action) => {
            state.isError = action.payload
        })
        .addCase(resetPasswordSuccess, (state) => {
            state.isError = false
            state.paswordReset = true
        })
        .addCase(completeNewPasswordError, (state) => {
            state.isError = true
            state.isAuthenticated = false
        })
        .addCase(completeNewPasswordSuccess, (state) => {
            state.isError = false
            state.isPasswordChanged = true
            state.isAuthenticated = false
        })
        .addCase(setNewPasswordError, (state) => {
            state.isError = true
            state.isAuthenticated = false
        })
        .addCase(setNewPasswordSuccess, (state, action) => {
            state.isError = false
            state.isPasswordChanged = true
            state.userDetails = action.payload
            state.isAuthenticated = false
        })
        .addCase(storeAuthToken, (state, action) => {
            state.token = action.payload.token
            state.issuer = action.payload.issuer
        })
        .addCase(setAuthStatus, (state, action) => {
            state.isAuthenticated = action.payload
        })
        .addCase(setLoading, (state, action) => {
            state.isLoading = action.payload
        })
        .addCase(setDispatchInfo, (state, action) => {
            state.dispatchInfo = action.payload
        })

})

export default caseReducer
