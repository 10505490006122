import { put, call, takeLeading } from "redux-saga/effects";
import { toast } from "react-toastify";
import url from "../../api";
import {
  createAmbulanceRequest,
  createAmbulanceRequestError,
  createAmbulanceRequestSuccess,
  updateAmbulanceRequest,
  updateAmbulanceRequestError,
  updateAmbulanceRequestSuccess,
  listAmbulanceProviders,
  listAmbulanceProvidersError,
  listAmbulanceProvidersSuccess,
  listAmbulanceRequests,
  listAmbulanceRequestsError,
  listAmbulanceRequestsSuccess,
  listSingleAmbulanceRequest,
  listSingleAmbulanceRequestError,
  listSingleAmbulanceRequestSuccess,
  listAmbulanceMetrics,
  listAmbulanceMetricsError,
  listAmbulanceMetricsSuccess,
  updateAmbulancePayment,
  updateAmbulancePaymentError,
  updateAmbulancePaymentSuccess,
  cancelAmbulancePayment,
  cancelAmbulancePaymentError,
  cancelAmbulancePaymentSuccess,
  approveAmbulanceRequest,
  approveAmbulanceRequestError,
  approveAmbulanceRequestSuccess,
} from "../actions/ambulanceActions";
import { storeAuthToken } from "../actions/authActions";
import { getAuthToken } from "./authSaga";
import * as Sentry from "@sentry/react";

function* listAmbulanceRequestsSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id, precinct_name } = _dispatcher?.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/ambulance-requests?task=trim`)
        : yield call(
            url.get,
            `/ambulance-requests?task=trim&precinct_id=${precinct_id}`
          );

    let { data } = response.data;
    yield put(listAmbulanceRequestsSuccess(data.ambulance_requests));
  } catch (error: Error | any) {
    Sentry.captureException(error);
    Sentry.flush(2000);
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(listAmbulanceRequestsError(error));
    }
    const message = error.response.data.message;
    toast.error(message);
    yield put(listAmbulanceRequestsError(message));
  }
}
function* listSingleAmbulanceRequestsSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { ambulanceId } = action.payload;
    const response: any = yield call(
      url.get,
      `/ambulance-requests/${ambulanceId}`
    );
    let { data } = response.data;
    yield put(listSingleAmbulanceRequestSuccess(data.ambulance_request));
  } catch (error: Error | any) {
    Sentry.captureException(error);
    Sentry.flush(2000);
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(listSingleAmbulanceRequestError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(listSingleAmbulanceRequestError(message));
  }
}

function* listAmbulanceMetricsSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const response: any = yield call(url.get, "/ambulance-requests/metrics");
    let { data } = response.data;

    yield put(listAmbulanceMetricsSuccess(data.ambulance_requests_count));
  } catch (error: Error | any) {
    Sentry.captureException(error);
    Sentry.flush(2000);
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(listAmbulanceMetricsError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(listAmbulanceMetricsError(message));
  }
}

function* createAmbulanceRequestSaga(action: any): Generator {
  const { requestData, callback } = action.payload;

  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id } = _dispatcher.data.data.dispatcher;
    requestData.precinct_id = precinct_id;

    const response: any = yield call(
      url.post,
      "/ambulance-requests",
      requestData
    );
    toast.success("Ambulance request created sucessfully");
    // const timeout = setTimeout(() => {
    //   close()
    // }, 5000)

    // clearTimeout(timeout)
    const { data } = response.data;
    callback?.onSuccess && callback?.onSuccess();
    yield put(createAmbulanceRequestSuccess(data));
    yield put(listAmbulanceRequests());
  } catch (error: Error | any) {
    callback?.onError && callback?.onError();
    if (error.toString() === "Error: Network Error") {
      Sentry.captureException(error);
      Sentry.flush(2000);
      console.log("Please check your network connection and try again");
      yield put(createAmbulanceRequestError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(createAmbulanceRequestError(message));
  }
}

function* listAmbulanceProvidersSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(
      url.get,
      `/dispatchers/${_authToken.user_id}`
    );
    const { precinct_id } = _dispatcher.data.data.dispatcher;

    const response: any = yield call(
      url.get,
      `/ambulance-providers?precinct_id=${precinct_id}`
    );
    let { data } = response.data;

    yield put(listAmbulanceProvidersSuccess(data.ambulance_providers));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(listAmbulanceProvidersError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(listAmbulanceProvidersError(message));
  }
}

function* updateAmbulanceRequestSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { requestData, callback, id } = action.payload;
    let response: any;

    response = yield call(url.put, `ambulance-requests/${id}`, requestData);

    const { data, message } = response.data;
    toast.success(message ? message : "Payment status updated sucessfully");
    yield put(updateAmbulanceRequestSuccess(data));
    callback && callback?.onSuccess && callback.onSuccess(data);
    // yield put(listAmbulanceRequests());
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateAmbulanceRequestError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updateAmbulanceRequestError(message));
  }
}

function* updatePaymentSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    // const { id, status, pay_status } = action.payload;
    const { id, pay_status } = action.payload;
    let response: any;
    if (pay_status === "requires_payment") {
      response = yield call(
        url.post,
        `ambulance-requests/${id}/payment/unconfirm`
      );
    } else if (pay_status === "confirmed") {
      response = yield call(
        url.post,
        `ambulance-requests/${id}/payment/confirm`
      );
    }
    const { data } = response.data;
    toast.success("Payment status updated sucessfully");
    yield put(updateAmbulancePaymentSuccess(data));
    yield put(listAmbulanceRequests());
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateAmbulancePaymentError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updateAmbulancePaymentError(message));
  }
}
function* cancelPaymentSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { id, requestData, status } = action.payload;
    let response: any;

    if (status === "open") {
      response = yield call(
        url.post,
        `ambulance-requests/${id}/cancel`,
        requestData
      );
    } else {
      response = yield call(
        url.post,
        `ambulance-requests/${id}/restore`,
        requestData
      );
    }

    const { data, message } = response.data;
    toast.success(
      message ? message : "Request is cancelled/restored sucessfully"
    );
    yield put(cancelAmbulancePaymentSuccess(data));
    yield put(listAmbulanceRequests());
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(cancelAmbulancePaymentError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(cancelAmbulancePaymentError(message));
  }
}

function* approveAmbulanceRequestSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { ambulance_request_id, ambulance_provider_id, callback } =
      action.payload;
    let response: any = yield call(
      url.post,
      `ambulance-requests/${ambulance_request_id}/approve`,
      { ambulance_provider_id }
    );
    const { data, message } = response.data;
    toast.success(message ? message : "Request approved sucessfully");
    callback();
    yield put(approveAmbulanceRequestSuccess(data));
    yield put(listAmbulanceRequests());
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(approveAmbulanceRequestError(error));
    }
    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(approveAmbulanceRequestError(message));
  }
}

function* ambulanceSaga() {
  yield takeLeading(listAmbulanceRequests, listAmbulanceRequestsSaga);
  yield takeLeading(createAmbulanceRequest, createAmbulanceRequestSaga);
  yield takeLeading(listAmbulanceProviders, listAmbulanceProvidersSaga);
  yield takeLeading(updateAmbulancePayment, updatePaymentSaga);
  yield takeLeading(cancelAmbulancePayment, cancelPaymentSaga);
  yield takeLeading(listAmbulanceMetrics, listAmbulanceMetricsSaga);
  yield takeLeading(approveAmbulanceRequest, approveAmbulanceRequestSaga);
  yield takeLeading(
    listSingleAmbulanceRequest,
    listSingleAmbulanceRequestsSaga
  );
  yield takeLeading(updateAmbulanceRequest, updateAmbulanceRequestSaga);
}

export default ambulanceSaga;
