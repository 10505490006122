import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Toaster } from "react-hot-toast";
import "firebase/messaging";

import "animate.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import PageLoader from "./components/common/PageLoader";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import appStore from "./store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const { store, persistor } = appStore;

Sentry.init({
  dsn: "https://c4c92b126f48444eb0ebbdc24ad62adc@o850911.ingest.sentry.io/5849084",
  release: "my-project-name@" + process.env.npm_package_version,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<PageLoader />}>
        <Router>
          <Toaster position="top-right" />
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
