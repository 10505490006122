/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from '@reduxjs/toolkit'
import {
    getAllResponders, getAllRespondersError, getAllRespondersSuccess, getResponder, getResponderError, getResponderSuccess, updateResponder, updateResponderError, updateResponderSuccess,
    createResponder, createResponderError, createResponderSuccess, getMultipleResponders, getMultipleRespondersError, getMultipleRespondersSuccess
} from '../actions/respondersActions'
import { ResponderType } from '../../utils/types'

type initialStateType = {
  loading: boolean,
  responders: ResponderType[],
  singleResponder: ResponderType | {},
  multipleResponders: ResponderType[],
  error: Error | null
}

const initialState: initialStateType = {
  loading: false,
  responders: [],
  singleResponder: {},
  multipleResponders: [],
  error: null,
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllResponders, (state, action) => {
      state.loading = true
    })
    .addCase(getAllRespondersError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getAllRespondersSuccess, (state, action) => {
      state.loading = false
      state.responders = action.payload
    })
    .addCase(getResponder, (state, action) => {
      state.loading = true
    })
    .addCase(getResponderError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getResponderSuccess, (state, action) => {
      state.loading = false
      state.singleResponder = action.payload
    })
    .addCase(updateResponder, (state, action) => {
      state.loading = true
    })
    .addCase(updateResponderError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateResponderSuccess, (state, action) => {
      state.loading = false
    })
    .addCase(createResponder, (state, action) => {
      state.loading = true
    })
    .addCase(createResponderError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(createResponderSuccess, (state, action) => {
      state.loading = false
    })
    .addCase(getMultipleResponders, (state, action) => {
      state.loading = true
    })
    .addCase(getMultipleRespondersError, (state, action) => {
      state.loading = false
    })
    .addCase(getMultipleRespondersSuccess, (state, action) => {
      state.loading = false
      state.multipleResponders = action.payload
    })

})

export default caseReducer
