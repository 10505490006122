/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getAllUsersError,
  getAllUsersSuccess,
  getUser,
  getUserError,
  getUserSuccess,
  getAllUsersLookup,
  getAllUsersLookupSuccess,
} from "../actions/usersActions";


interface IinitialState {
  loading: boolean;
  users: { [key: string]: any | { [key: string]: any } }[];
  singleUser: { [key: string]: any };
  error: string | any;
  test: { [key: string]: any }[];
}

const initialState: IinitialState = {
  loading: false,
  users: [],
  singleUser: [],
  error: null,
  test: [],
};

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllUsers, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllUsersSuccess, (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase(getAllUsersError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getUser, (state, action) => {
      state.loading = true;
    })
    .addCase(getUserError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getUserSuccess, (state, action) => {
      state.loading = false;
      state.singleUser = action.payload;
    })
    .addCase(getAllUsersLookup, (state, action) => {
      state.loading = true;
      // state.test = action.payload
    })
    .addCase(getAllUsersLookupSuccess, (state, action) => {
      state.loading = true;
      state.test = action.payload;
    });
});

export default caseReducer;
