/* eslint-disable import/no-anonymous-default-export */
import {
    put, call, takeLeading
} from 'redux-saga/effects'
import url from '../../api'

import { 
    getAllHospitalTransferSuccess, getAllHospitalTransferError, getAllHospitalTransfer, 
    addHospitalTransfer, addHospitalTransferSuccess, addHospitalTransferError
} from '../actions/hospitalTransferActions'

import { toast } from 'react-toastify'

import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'


function* getHospitalCasesSaga(): Generator {
    try {
        const _authToken: any = yield call(getAuthToken)
        yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
        const response: any = yield call(url.get, '/hospitals/cases?status=new&limit=0');
        const { data } = response.data;
        yield put(getAllHospitalTransferSuccess(data.hospital_cases));
    } catch (error: Error | any) {
        if (error.toString() === 'Error: Network Error') {
            console.log("Please check your network connection and try again");
            yield put(getAllHospitalTransferError(error));
        }
        const message = error?.response?.data?.message;

        yield put(getAllHospitalTransferError(message));
    }
}

function* createHospitalCaseSaga(action: any): Generator {
    const { id, case_id, status, report, notes, callback } = action.payload
    try {
        const _authToken: any = yield call(getAuthToken)
        yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
        const response: any = yield call(url.post, `/hospitals/${id}/cases`, { case_id, status, report, notes });
        const { data } = response.data;
        toast.success('Hospital has been notified')
        callback.onSuccess();
        yield put(addHospitalTransferSuccess(data))
    } catch (error: Error | any) {
        callback.onError();
        if (error.toString() === 'Error: Network Error') {
            console.log("Please check your network connection and try again");
            yield put(addHospitalTransferError(error));
        }
        const {
            data: { message },
        } = error.response;
        toast.error(message)
        yield put(addHospitalTransferError(message));
    }
}

function* hospitalCasesSaga() {
    yield takeLeading(getAllHospitalTransfer, getHospitalCasesSaga);
    yield takeLeading(addHospitalTransfer, createHospitalCaseSaga);
}

export default hospitalCasesSaga