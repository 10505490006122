import { spawn } from 'redux-saga/effects';
import casesSaga from './casesSaga'
import hospitalCasesSaga from './hospitalCasesSaga'
import authSaga from './authSaga'
import respondersSaga from './responderSaga'
import hospitalSaga from './hospitalSaga'
import userSaga from './userSaga'
import dispatcherSaga from './dispatcherSaga'
import uploadSaga from './uploadAvatarSaga'
import ambulanceSaga from './ambulanceSaga'


function* rootSaga() {
    yield spawn(casesSaga);
    yield spawn(hospitalCasesSaga);
    yield spawn(authSaga);
    yield spawn(respondersSaga);
    yield spawn(hospitalSaga);
    yield spawn(userSaga);
    yield spawn(dispatcherSaga);
    yield spawn(uploadSaga)
    yield spawn(ambulanceSaga)
}

export default rootSaga;