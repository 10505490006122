export const CASES = {
  getAllCases: "GET_ALL_CASES",
  getAllCasesOverview: "GET_ALL_CASES_OVERVIEW",
  getCasesDashboard: "GET_ALL_CASES_DASHBOARD",
  getAllCasesPage: "GET_ALL_CASES_PAGE",
  casesPageRange: "CASES_PAGE_RANGE",
  getAllCasesError: "GET_ALL_CASES_ERROR",
  getAllCasesOverviewError: "GET_ALL_CASES_ERROR_OVERVIEW",
  getCasesDashboardError: "GET_ALL_CASES_ERROR_DASHBOARD",
  getAllCasesSuccess: "GET_ALL_CASES_SUCCESS",
  getAllCasesOverviewSuccess: "GET_ALL_CASES_SUCCESS_OVERVIEW",
  getAllCasesSuccessPage: "GET_ALL_CASES_SUCCESS_PAGES",
  getCasesDashboardSuccess: "GET_ALL_CASES_SUCCESS_DASHBOARD",
  getAllPagesCasesError: "GET_ALL_PAGES_CASES_ERROR",
  getCase: "GET_CASE",
  getCaseError: "GET_CASE_ERROR",
  getCaseSuccess: "GET_CASE_SUCCESS",
  getCaseResponders: "GET_CASE_RESPONDERS",
  getCaseRespondersError: "GET_CASE_RESPONDERS_ERROR",
  getCaseRespondersSuccess: "GET_CASE_RESPONDERS_SUCCESS",
  addCase: "ADD_CASE",
  addCaseError: "ADD_CASE_ERROR",
  addCaseSuccess: "ADD_CASE_SUCCESS",
  updateCase: "UPDATE_CASE",
  updateCaseError: "UPDATE_CASE_ERROR",
  updateCaseSuccess: "UPDATE_CASE_SUCCESS",
  getIncidentReport: "GET_INCIDENT_REPORT",
  getIncidentReportError: "GET_INCIDENT_REPORT_ERROR",
  getIncidentReportSuccess: "GET_INCIDENT_REPORT_SUCCESS",
  setLoading: "SET_LOADING",
  addInquiry: "ADD_INQUIRY",
  addInquirySuccess: "ADD_INQUIRY_SUCCESS",
  addInquiryError: "ADD_INQUIRY_ERROR",
  updateInquiry: "UPDATE_INQUIRY",
  updateInquiryError: "UPDATE_INQUIRY_ERROR",
  updateInquirySuccess: "UPDATE_INQUIRY_SUCCESS",
  getReference: "GET_REFERENCE",
  getReferenceError: "GET_REFERENCE_ERROR",
  getReferenceSuccess: "GET_REFERENCE_SUCCESS",
  getProbableCause: "GET_PROBABLE_CAUSE",
  getProbableCauseError: "GET_PROBABLE_CAUSE_ERROR",
  getProbableCauseSuccess: "GET_PROBABLE_CAUSE_SUCCESS",
  getSeverity: "GET_SEVERITY_CAUSE",
  getSeverityError: "GET_SEVERITY_ERROR",
  getSeveritySuccess: "GET_SEVERITY_SUCCESS",
  getDiagnosis: "GET_DIAGNOSOS",
  getDiagnosisError: "GET_DIAGNOSOS_ERROR",
  getDiagnosisSuccess: "GET_DIAGNOSOS_SUCCESS",
  getInquiries: "GET_INQUIRIES",
  getInquiriesError: "GET_INQUIRIES_ERROR",
  getInquiriesSuccess: "GET_INQUIRIES_SUCCESS",
  getInquiry: "GET_INQUIRY",
  getInquiryError: "GET_INQUIRY_ERROR",
  getInquirySuccess: "GET_INQUIRY_SUCCESS",
  closeCase: "CLOSE_CASE",
  closeCaseError: "CLOSE_CASE_ERROR",
  closeCaseSuccess: "CLOSE_CASE_SUCCESS",
  approveCase: "APPROVE_CASE",
  approveCaseError: "APPROVE_CASE_ERROR",
  approveCaseSuccess: "APPROVE_CASE_SUCCESS",
  getIncidentTypes: "GET_INCIDENT_TYPES",
  getIncidentTypesError: "GET_INCIDENT_TYPES_ERROR",
  getIncidentTypesSuccess: "GET_INCIDENT_TYPES_SUCCESS",
  getActionTypes: "GET_ACTION_TYPES",
  getActionTypesError: "GET_ACTION_TYPES_ERROR",
  getActionTypesSuccess: "GET_ACTION_TYPES_SUCCESS",
  getPartners: "GET_PARTNERS",
  getPartnersSuccess: "GET_PARTNERS_SUCCESS",
  getPartnersError: "GET_PARTNERS_ERROR",
};

export const HOSPITAL_TRANSFER = {
  getAllHospitalTransfer: "GET_HOSPITALS_TRANSFER",
  getAllHospitalTransferError: "GET_HOSPITALS_TRANSFER_ERROR",
  getAllHospitalTransferSuccess: "GET_HOSPITALS_TRANSFER_SUCCESS",
  getHospitalTransfer: "GET_HOSPITAL_TRANSFER",
  getHospitalTransferError: "GET_HOSPITAL_TRANSFER_ERROR",
  getHospitalTransferSuccess: "GET_HOSPITAL_TRANSFER_SUCCESS",
  addHospitalTransfer: "ADD_HOSPITAL_TRANSFER",
  addHospitalTransferError: "ADD_HOSPITAL_TRANSFER_ERROR",
  addHospitalTransferSuccess: "ADD_HOSPITAL_TRANSFER_SUCCESS",
  updateHospitalTransfer: "UPDATE_HOSPITAL_TRANSFER",
  updateHospitalTransferError: "UPDATE_HOSPITAL_TRANSFER_ERROR",
  updateHospitalTransferSuccess: "UPDATE_HOSPITAL_TRANSFER_SUCCESS",
  setLoading: "SET_LOADING",
};

export const AUTH = {
  createUser: "CREATE_USER",
  createUserError: "CREATE_USER_ERROR",
  createUserSuccess: "CREATE_USER_SUCCESS",
  signIn: "SIGN_IN",
  signInError: "SIGN_IN_ERROR",
  signInSuccess: "SIGN_IN_SUCCESS",
  logout: "LOGOUT",
  logoutError: "LOGOUT_ERROR",
  logoutSuccess: "LOGOUT_SUCCESS",
  resetPassword: "FORGOT_PASSWORD",
  resetPasswordError: "FORGOT_PASSWORD_ERROR",
  resetPasswordSuccess: "FORGOT_PASSWORD_SUCCESS",
  completeNewPassword: "COMPLETE_NEW_PASSWORD",
  completeNewPasswordError: "COMPLETE_NEW_PASSWORD_ERROR",
  completeNewPasswordSuccess: "COMPLETE_NEW_PASSWORD_SUCCESS",
  setNewPassword: "SET_NEW_PASSWORD",
  setNewPasswordError: "SET_NEW_PASSWORD_ERROR",
  setNewPasswordSuccess: "SET_NEW_PASSWORD_SUCCESS",
  storeAuthToken: "SAVE_AUTH_TOKEN",
  setAuthStatus: "SET_AUTH_STATUS",
  mfa: "MFA",
  mfaError: "MFA_ERROR",
  mfaSuccess: "MFA_SUCCESS",
  setAuthentication: "SET_AUTHENTICATION",
  setLoading: "SET_LOADING",
  setDispatchInfo: "SET_DISPATCH_INFO",
};

export const RESPONDERS = {
  getAllResponders: "GET_ALL_RESPONDERS",
  getAllRespondersError: "GET_ALL_RESPONDERS_ERROR",
  getAllRespondersSuccess: "GET_ALL_RESPONDERS_SUCCESS",
  getResponder: "GET_RESPONDER",
  getResponderError: "GET_RESPONDER_ERROR",
  getResponderSuccess: "GET_RESPONDER_SUCCESS",
  updateResponder: "UPDATE_RESPONDER",
  updateResponderError: "UPDATE_RESPONDER_ERROR",
  updateResponderSuccess: "UPDATE_RESPONDER_SUCCESS",
  createResponder: "CREATE_RESPONDER",
  createResponderError: "CREATE_RESPONDER_ERROR",
  createResponderSuccess: "CREATE_RESPONDER_SUCCESS",
  getMultipleResponders: "GET_MULTIPLE_RESPONDERS",
  getMultipleRespondersError: "GET_MULTIPLE_RESPONDERS_ERROR",
  getMultipleRespondersSuccess: "GET_MULTIPLE_RESPONDERS_SUCCESS",
  setLoading: "SET_LOADING",
  notifyResponder: "NOTIFY_RESPONDER",
  notifyResponderError: "NOTIFY_RESPONDER_ERROR",
  notifyResponderSuccess: "NOTIFY_RESPONDER_SUCCESS"
};

export const HOSPITALS = {
  getAllHospital: "GET_ALL_HOSPITALS",
  getAllHospitalError: "GET_ALL_HOSPITALS_ERROR",
  getAllHospitalSuccess: "GET_ALL_HOSPITALS_SUCCESS",
  getHospital: "GET_HOSPITAL",
  getHospitalError: "GET_HOSPITAL_ERROR",
  getHospitalSuccess: "GET_HOSPITAL_SUCCESS",
  updateHospital: "UPDATE_HOSPITAL",
  updateHospitalError: "UPDATE_HOSPITAL_ERROR",
  updateHospitalSuccess: "UPDATE_HOSPITAL_SUCCESS",
  createHospital: "CREATE_HOSPITAL",
  createHospitalError: "CREATE_HOSPITAL_ERROR",
  createHospitalSuccess: "CREATE_HOSPITAL_SUCCESS",
  setLoading: "SET_LOADING",
  getRecommendedHospital: "GET_RECOMMENDED_HOSPITAL",
  getRecommendedHospitalError: "GET_RECOMMENDED_HOSPITAL_ERR",
  getRecommendedHospitalSuccess: "GET_RECOMMENDED_HOSPITAL_SUCCESS",
};

export const USERS = {
  getAllUsers: "GET_ALL_USERS",
  getAllUsersError: "GET_ALL_USERS_ERROR",
  getAllUsersSuccess: "GET_ALL_USERS_SUCCESS",
  getUser: "GET_USER",
  getUserError: "GET_USER_ERROR",
  getUserSuccess: "GET_USER_SUCCESS",
  setLoading: "SET_LOADING",
  getAllUsersLookup: "GET_ALL_USERS_LOOKUP",
  getAllUsersLookupSuccess: "GET_ALL_USERS_LOOKUP_SUCCESS"
};

export const DISPATCHER = {
  getdispatcher: "GET_DISPATCHER",
  getdispatcherError: "GET_DISPATCHER_ERROR",
  getdispatcherSuccess: "GET_DISPATCHER_SUCCESS",
  updatedispatcher: "UPDATE_DISPATCHER",
  updatedispatcherError: "UPDATE_DISPATCHER_ERROR",
  updatedispatcherSuccess: "UPDATE_DISPATCHER_SUCCESS",
  getNotifications: "GET_NOTIFICATIONS",
  getNoficationsError: "GET_NOTIFICATIONS_ERROR",
  getNotificationsSuccess: "GET_NOTIFICATIONS_SUCCESS",
  updateNotifications: "UPDATE_NOTIFICATIONS",
  updateNoficationsError: "UPDATE_NOTIFICATIONS_ERROR",
  updateNotificationsSuccess: "UPDATE_NOTIFICATIONS_SUCCESS",
};
export const UPLOAD = {
  uploadAvatar: "UPLOAD_AVATAR",
  uploadAvatarError: "UPLOAD_AVATARERROR",
  uploadAvatarsSuccess: "UPLOAD_AVATARSUCCESS",
};

export const AMBULANCE = {
  listAmbulanceRequests: "LIST_AMBULANCE_REQUESTS",
  listAmbulanceRequestsError: "LIST_AMBULANCE_REQUESTS_ERROR",
  listAmbulanceRequestsSucccess: "LIST_AMBULANCE_REQUESTS_SUCCESS",
  listSingleAmbulanceRequest: "LIST_SINGLE_AMBULANCE_REQUEST",
  listSingleAmbulanceRequestError: "LIST_SINGLE_AMBULANCE_REQUEST_ERROR",
  listSingleAmbulanceRequestSucccess: "LIST_SINGLE_AMBULANCE_REQUEST_SUCCESS",
  createAmbulanceRequest: "CREATE_AMBULANCE_REQUEST",
  createAmbulanceRequestError: "CREATE_AMBULANCE_REQUEST_ERROR",
  createAmbulanceRequestSuccess: "CREATE_AMBULANCE_REQUEST_SUCCESS",
  updateAmbulanceRequest: "UPDATE_AMBULANCE_REQUEST",
  updateAmbulanceRequestError: "UPDATE_AMBULANCE_REQUEST_ERROR",
  updateAmbulanceRequestSuccess: "UPDATE_AMBULANCE_REQUEST_SUCCESS",
  listAmbulanceProviders: "LIST_AMBULANCE_PROVIDERS",
  listAmbulanceProvidersError: "LIST_AMBULANCE_PROVIDERS_ERROR",
  listAmbulanceProvidersSucccess: "LIST_AMBULANCE_PROVIDERS_SUCCESS",
  updateAmbulancePayment: "UPDATE_AMBULANCE_PAYMENT",
  updateAmbulancePaymentError: "UPDATE_AMBULANCE_PAYMENT_ERROR",
  updateAmbulancePaymentSucccess: "UPDATE_AMBULANCE_PAYMENT_SUCCESS",
  cancelAmbulancePayment: "CANCEL_AMBULANCE_PAYMENT",
  cancelAmbulancePaymentError: "CANCEL_AMBULANCE_PAYMENT_ERROR",
  cancelAmbulancePaymentSucccess: "CANCEL_AMBULANCE_PAYMENT_SUCCESS",
  listAmbulanceMetrics: "LIST_AMBULANCE_METRICS",
  listAmbulanceMetricsError: "LIST_AMBULANCE_METRICS_ERROR",
  listAmbulanceMetricsSuccess: "LIST_AMBULANCE_METRICS_SUCCESS",
  approveAmbulanceRequest: "APPROVE_AMBULANCE_REQUEST",
  approveAmbulanceRequestError: "APPROVE_AMBULANCE_REQUEST_ERROR",
  approveAmbulanceRequestSuccess: "APPROVE_AMBULANCE_REQUEST_SUCCESS",
  updateAmbulanceModal: "UPDATE_AMBULANCE_MODAL",
  updateAmbulanceModalError: "UPDATE_AMBULANCE_MODAL_ERROR",
  updateAmbulanceModalSuccess: "UPDATE_AMBULANCE_MODAL_SUCCESS",
};
