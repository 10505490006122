import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";

interface IProtectedRoute {
  component: any;
}

const ProtectedRoute: React.FC<IProtectedRoute & Record<string, any>> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, userDetails } = useSelector((state: RootState) => state.auth)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated &&
          userDetails?.challengeName !== "NEW_PASSWORD_REQUIRED"
        ) {
          return <Component {...props} {...rest} />;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
