/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from "@reduxjs/toolkit";
import {
  getdispatcher,
  getdispatcherError,
  getdispatcherSuccess,
  getNoficationsError,
  getNotifications,
  getNotificationsSuccess,
  updateNoficationsError,
  updateNotifications,
  updateNotificationsSuccess,
  updatedispatcher,
  updatedispatcherError,
  updatedispatcherSuccess,
} from "../actions/dispatcherActions";

const initialState = {
  loading: false,
  dispatcher: [],
  notifications: [],
  error: null,
};

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getdispatcher, (state) => {
      state.loading = true;
    })
    .addCase(getdispatcherError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getdispatcherSuccess, (state, action) => {
      state.loading = false;
      state.dispatcher = action.payload;
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .addCase(updatedispatcher, (state, action) => {
      state.loading = true;
    })
    .addCase(updatedispatcherError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updatedispatcherSuccess, (state, action) => {
      state.loading = false;
    })
    .addCase(getNotifications, (state) => {
      state.loading = true;
    })
    .addCase(getNoficationsError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getNotificationsSuccess, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    })
    .addCase(updateNotifications, (state) => {
      state.loading = true;
    })
    .addCase(updateNoficationsError, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateNotificationsSuccess, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    });
});

export default caseReducer;
