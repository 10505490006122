import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from '../../store/reducers'

interface IPasswordChange {
  component: any;
  [x: string]: any;
}

const ProtectedRoute: React.FC<IPasswordChange> = ({ component: Component, ...rest }) => {
  const { userDetails } = useSelector((state: RootState) => state.auth)

  return (
    <Route
      {...rest}
      render={props => {
        if (userDetails?.challengeName === "NEW_PASSWORD_REQUIRED") {
          return <Component {...props} {...rest} />
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  )

}

export default ProtectedRoute;