import { isEmpty } from "lodash";
import { useState, createContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";

interface IAuthContext {
  userInfo: any;
  setUserInfo: (userInfo: any) => void;
  otherDetails: any;
  setOtherDetails: (otherDetails: any) => void;
}

const AuthContext = createContext<IAuthContext>({
  userInfo: {},
  setUserInfo: () => true,
  otherDetails: {},
  setOtherDetails: () => true,
});

let _userInfo: any = {};

if (localStorage.getItem("userInfo")) {
  _userInfo = localStorage.getItem("userInfo");
  _userInfo = JSON.parse(_userInfo);
}

const AuthProvider = (props: any) => {
  const { userDetails }: any = useSelector((state: RootState) => state.auth);

  const [userInfo, setUserInfo] = useState(_userInfo);
  const [otherDetails, setOtherDetails] = useState({});

  const userMemo = useMemo(() => {
    return userDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (!isEmpty(userMemo)) {
      try {
        setUserInfo(userMemo);
        localStorage.setItem("userInfo", JSON.stringify(userMemo));
      } catch (error) {
        localStorage.getItem(userInfo);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMemo]);

  return (
    <AuthContext.Provider
      value={{ userInfo, setUserInfo, otherDetails, setOtherDetails }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
