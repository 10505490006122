import { createAction } from '@reduxjs/toolkit'
import { AUTH } from './types'


export const createUser = createAction<any>(
    AUTH.createUser
);

export const storeAuthToken = createAction<any>(
    AUTH.storeAuthToken
);

export const createUserSuccess = createAction<any>(
    AUTH.createUserSuccess
)

export const createUserError = createAction<any>(
    AUTH.createUserError
)

export const signin = createAction<any>(
    AUTH.signIn,
)

export const signinSuccess = createAction<any>(
    AUTH.signInSuccess
)

export const signinError = createAction(
    AUTH.signInError
)

export const logout = createAction(
    AUTH.logout
)

export const logoutSuccess = createAction(
    AUTH.logoutSuccess
)

export const logoutError = createAction(
    AUTH.logoutError
)

export const resetPassword = createAction<any>(
    AUTH.resetPassword
)

export const resetPasswordSuccess = createAction<any>(
    AUTH.resetPasswordSuccess
)

export const resetPasswordError = createAction<any>(
    AUTH.resetPasswordError
)

export const completeNewPassword = createAction<any>(
    AUTH.completeNewPassword
)

export const completeNewPasswordSuccess = createAction(
    AUTH.completeNewPasswordSuccess
)

export const completeNewPasswordError = createAction(
    AUTH.completeNewPasswordError
)
export const setNewPassword = createAction<any>(
    AUTH.setNewPassword
)

export const setNewPasswordSuccess = createAction<any>(
    AUTH.setNewPasswordSuccess
)

export const setNewPasswordError = createAction<any>(
    AUTH.setNewPasswordError
)

export const setAuthStatus = createAction<boolean>(
    AUTH.setAuthStatus
)

export const mfa = createAction<any>(
    AUTH.mfa
)

export const mfaError = createAction(
    AUTH.mfaError
)

export const mfaSuccess = createAction<any>(
    AUTH.mfaSuccess
)

export const setAuthentication = createAction<boolean>(
    AUTH.setAuthentication
)

export const setLoading = createAction<boolean>(
    AUTH.setLoading
)

export const setDispatchInfo = createAction<any>(
    AUTH.setDispatchInfo
)
