import { createAction } from '@reduxjs/toolkit'
import { DISPATCHER } from './types'

export const getdispatcher = createAction<any>(
  DISPATCHER.getdispatcher
)

export const getdispatcherError = createAction<any>(
  DISPATCHER.getdispatcherError
)

export const getdispatcherSuccess = createAction<any>(
  DISPATCHER.getdispatcherSuccess
)
export const updatedispatcher = createAction<any>(
  DISPATCHER.updatedispatcher
)

export const updatedispatcherError = createAction<any>(
  DISPATCHER.updatedispatcherError
)

export const updatedispatcherSuccess = createAction<any>(
  DISPATCHER.updatedispatcherSuccess
)

export const getNotifications = createAction(
  DISPATCHER.getNotifications
)

export const getNoficationsError = createAction<any>(
  DISPATCHER.getNoficationsError
)

export const getNotificationsSuccess = createAction<any>(
  DISPATCHER.getNotificationsSuccess
)
export const updateNotifications = createAction<any>(
  DISPATCHER.updateNotifications
)

export const updateNoficationsError = createAction<any>(
  DISPATCHER.updateNoficationsError
)

export const updateNotificationsSuccess = createAction<any>(
  DISPATCHER.updateNotificationsSuccess
)

